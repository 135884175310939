var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("page-snippet", { attrs: { name: "upload-asset" } }),
              _c(
                "div",
                {
                  staticClass: "dropzone",
                  class: { dropzone: true, dragover: _vm.dragover },
                  on: {
                    drop: function($event) {
                      $event.preventDefault()
                      return _vm.addFile($event)
                    },
                    dragover: function($event) {
                      $event.preventDefault()
                    },
                    dragenter: function($event) {
                      _vm.dragover = true
                    },
                    dragleave: function($event) {
                      _vm.dragover = false
                    }
                  }
                },
                [_vm._v(" Sleep hier de audio/artwork heen (wav, jpg) ")]
              ),
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.uploadProblemSecs,
                    callback: function($$v) {
                      _vm.uploadProblemSecs = $$v
                    },
                    expression: "uploadProblemSecs"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.uploadProblems) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "8" } },
            [
              _c("b-card", { attrs: { header: "Tracks" } }, [
                _vm.audioFiles.length == 0
                  ? _c("div", [
                      _vm._v(" Upload wav bestanden om tracks toe te voegen ")
                    ])
                  : _vm._e(),
                _vm.audioFiles.length > 0
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-table-simple",
                          { attrs: { striped: "" } },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("#")
                                ]),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Bestandsnaam")
                                ]),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Informatie")
                                ]),
                                _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v("Status")
                                ]),
                                _c("th", { attrs: { scope: "col" } })
                              ])
                            ]),
                            _c(
                              "draggable",
                              {
                                attrs: { tag: "tbody" },
                                model: {
                                  value: _vm.audioFiles,
                                  callback: function($$v) {
                                    _vm.audioFiles = $$v
                                  },
                                  expression: "audioFiles"
                                }
                              },
                              _vm._l(_vm.audioFiles, function(file, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", [_vm._v(_vm._s(index + 1))]),
                                  _c("td", { attrs: { scope: "row" } }, [
                                    _vm._v(_vm._s(file.name))
                                  ]),
                                  _c(
                                    "td",
                                    { attrs: { scope: "row" } },
                                    [
                                      file.info && file.info.format == "wav"
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "font-weight-bold": true,
                                                  "text-success":
                                                    file.info.bitsPerSample >=
                                                    16,
                                                  "text-danger":
                                                    file.info.bitsPerSample < 16
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      file.info.bitsPerSample
                                                    ) +
                                                    "bps "
                                                )
                                              ]
                                            ),
                                            _vm._v(" / "),
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "font-weight-bold": true,
                                                  "text-success":
                                                    file.info.sampleRate >=
                                                    44100,
                                                  "text-danger":
                                                    file.info.sampleRate < 44100
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      file.info.sampleRate
                                                    ) +
                                                    "Hz "
                                                )
                                              ]
                                            ),
                                            _vm._v(" / "),
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "font-weight-bold": true,
                                                  "text-success":
                                                    file.info.channels == 2,
                                                  "text-danger":
                                                    file.info.channels != 2
                                                }
                                              },
                                              [
                                                file.info.channels == 1
                                                  ? [_vm._v(" Mono ")]
                                                  : _vm._e(),
                                                file.info.channels == 2
                                                  ? [_vm._v(" Stereo ")]
                                                  : _vm._e(),
                                                ![1, 2].includes(
                                                  file.info.channels
                                                )
                                                  ? [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            file.info.channels
                                                          ) +
                                                          "ch "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ]
                                        : _vm._e(),
                                      !file.info || file.info.format != "wav"
                                        ? [
                                            file.info && file.info.format
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Formaat niet ondersteund: " +
                                                        _vm._s(
                                                          file.info.format
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _c(
                                    "td",
                                    [
                                      file.status != "uploading"
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.statusText[file.status]
                                              )
                                            )
                                          ])
                                        : _vm._e(),
                                      file.status == "uploading"
                                        ? _c("b-progress", {
                                            attrs: {
                                              value: file.uploadPercentage,
                                              max: 100,
                                              "show-progress": ""
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { small: "", title: "Remove" },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeAudioFile(file)
                                            }
                                          }
                                        },
                                        [_vm._v("X")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              }),
                              0
                            )
                          ],
                          1
                        ),
                        _c("p", { staticClass: "mt-4" }, [
                          _vm._v(
                            "Je kunt de tracks slepen om ze in de juiste volgorde te zetten."
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm.artwork && _vm.artwork.status == "uploadComplete"
                ? [
                    _c(
                      "b-alert",
                      {
                        staticClass: "mt-4",
                        attrs: { variant: "danger" },
                        model: {
                          value: _vm.artworkTooSmall,
                          callback: function($$v) {
                            _vm.artworkTooSmall = $$v
                          },
                          expression: "artworkTooSmall"
                        }
                      },
                      [
                        _vm._v(
                          " Het artworkbestand is kleiner dan 3000x3000 pixels. "
                        )
                      ]
                    ),
                    _c(
                      "b-alert",
                      {
                        staticClass: "mt-4",
                        attrs: { variant: "danger" },
                        model: {
                          value: _vm.artworkTooLarge,
                          callback: function($$v) {
                            _vm.artworkTooLarge = $$v
                          },
                          expression: "artworkTooLarge"
                        }
                      },
                      [
                        _vm._v(
                          " Het artworkbestand is groter dan 6000x6000 pixels. "
                        )
                      ]
                    ),
                    _c(
                      "b-alert",
                      {
                        attrs: { variant: "danger" },
                        model: {
                          value: _vm.artworkNotRgb,
                          callback: function($$v) {
                            _vm.artworkNotRgb = $$v
                          },
                          expression: "artworkNotRgb"
                        }
                      },
                      [_vm._v(" Het artworkbestand is geen RGB formaat. ")]
                    ),
                    _c(
                      "b-alert",
                      {
                        attrs: { variant: "danger" },
                        model: {
                          value: _vm.artworkPoorQuality,
                          callback: function($$v) {
                            _vm.artworkPoorQuality = $$v
                          },
                          expression: "artworkPoorQuality"
                        }
                      },
                      [
                        _vm._v(
                          " De kwaliteit van het artworkbestand is lager dan 75 "
                        )
                      ]
                    )
                  ]
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { disabled: !_vm.readyToProceed, variant: "primary" },
                  on: { click: _vm.proceed }
                },
                [
                  _vm.readyToProceed
                    ? _c("span", [_vm._v("Verder")])
                    : _vm._e(),
                  _vm.audioFiles.length == 0
                    ? _c("span", [
                        _vm._v("Voeg bestanden toe om verder te gaan")
                      ])
                    : _vm._e(),
                  !_vm.readyToProceed && _vm.audioFiles.length > 0
                    ? _c("span", [
                        _vm._v("U kunt verder als de upload gereed is")
                      ])
                    : _vm._e()
                ]
              )
            ],
            2
          ),
          _c(
            "b-col",
            [
              _c("b-card", { attrs: { header: "Artwork" } }, [
                _vm.artworkImageSrc == ""
                  ? _c("div", [
                      _vm._v(" Upload een RGB jpeg bestand als artwork ")
                    ])
                  : _vm._e(),
                _vm.artworkImageSrc != ""
                  ? _c(
                      "div",
                      [
                        _c("b-img", {
                          attrs: { fluid: "", src: _vm.artworkImageSrc }
                        }),
                        _c("p", { staticClass: "mt-4 text-center" }, [
                          _vm._v(_vm._s(_vm.statusText[_vm.artwork.status]))
                        ]),
                        _vm.artwork.status == "uploading"
                          ? _c("b-progress", {
                              attrs: {
                                value: _vm.artwork.uploadPercentage,
                                max: 100,
                                "show-progress": ""
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }